<template>
  <div class="flex flex-col items-center text-gray-200">
    <p class="my-2 text-sm italic">
      <span clas="font-bold">{{ asset.type }}</span> files cannot be previewed in browser
    </p>
    <p>
      <a
        :href="asset.url"
        _target="blank"
        class="btn"
      >Download</a>
    </p>
  </div>
</template>
<script>
export default {
  props: ['asset']
}
</script>
